
import {Component, PropSync, Vue} from "vue-property-decorator";
import {SecurityMethod} from "@/domain/security-info";

@Component
export default class AppDrawer extends Vue {
  @PropSync('isOpen', {type: Boolean})
  readonly isOpenModel!: boolean;

  get isCognito(): boolean {
    // @ts-ignore
    return this.$securityInfo.securityMethod == SecurityMethod.COGNITO;
  }

  cssVars() {
    return {
      '--drawer-primary': this.$vuetify.theme.themes.light.drawer_primary,
      '--drawer-icon': this.$vuetify.theme.themes.light.drawer_icon,
      '--drawer-secondary': this.$vuetify.theme.themes.light.drawer_secondary
    }
  }
}
