
    import {Component, Vue} from "vue-property-decorator";
    import {ChartData, ChartOptions} from "chart.js";
    import {namespace} from "vuex-class";
    import _ from "lodash";

    import {StatisticMessage} from "@/domain";
    import LineChart from "@/components/LineChart.vue";
    import PieChart from "@/components/PieChart.vue";
    import {StatisticDevice} from "@/domain/device";

    const dashBoardModule = namespace('dashboard');

    @Component({
        components: {PieChart, LineChart}
    })
    export default class Dashboard extends Vue {

        @dashBoardModule.State
        readonly totalMessages!: number;

        @dashBoardModule.State
        readonly messages!: StatisticMessage[];

        @dashBoardModule.State
        readonly chargePoints!: StatisticDevice;

        @dashBoardModule.State
        readonly centralSystems!: StatisticDevice;

        chartOptions: ChartOptions = {
            scales: {
                xAxes: [{
                    type: 'time',
                    distribution: 'series',
                    bounds: 'data',
                    time: {
                        round: 'minute'
                    }
                }],
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        };

        initialLoading: boolean = true;
        messagesLoading: boolean = false;
        devicesLoading: boolean = false;

        updateInterval: number | null = null;

        get iconSize() {
            return this.$vuetify.breakpoint.smAndDown ? 20 : 30;
        }

        get avatarSize() {
            return this.$vuetify.breakpoint.smAndDown ? 50 : 60;
        }

        get deviceData(): ChartData {
            return {
                labels: ["Charge Points", "Central Systems"],
                datasets: [{
                    backgroundColor: [String(this.$vuetify.theme.currentTheme.graph_first), String(this.$vuetify.theme.currentTheme.graph_second)],
                    data: [this.chargePoints.connected, this.centralSystems.connected]
                }]
            };
        }

        get messageData(): ChartData {
              return {
                datasets: [
                    {
                        label: 'Requests',
                        borderColor: String(this.$vuetify.theme.currentTheme.graph_first),
                        backgroundColor: String(this.$vuetify.theme.currentTheme.secondary),
                        fill: false,
                        data: _(this.messages)
                            .filter(message => message.messageType === 'REQUEST')
                            .map(message => ({x: new Date(message.date), y: message.count}))
                            .reverse()
                            .value()
                    },
                    {
                        label: 'Responses',
                        borderColor: String(this.$vuetify.theme.currentTheme.graph_second),
                        backgroundColor: String(this.$vuetify.theme.currentTheme.secondary),
                        fill: false,
                        data: _(this.messages)
                            .filter(message => message.messageType === 'RESPONSE')
                            .map(message => ({x: new Date(message.date), y: message.count}))
                            .reverse()
                            .value()
                    }
                ]
            };
        }

        async mounted() {
           await Promise.all([
               this.getMessages(),
               this.getDevices(),
               this.getAllMessages()
           ]);
           this.initialLoading = false;
        }

        beforeDestroy() {
            if (this.updateInterval != null) {
                clearInterval(this.updateInterval);
            }
        }

        async getMessages() {
            this.messagesLoading = true;
            await this.$store.dispatch("dashboard/fetchMessageStatistics");
            this.messagesLoading = false;
        }

        async getAllMessages() {
            await this.$store.dispatch("dashboard/fetchAllMessages");
        }

        async getDevices() {
            this.devicesLoading = true;
            await this.$store.dispatch("dashboard/fetchConnectedDevices");
            this.devicesLoading = false;
        }

    }
