

    import {Component, Vue, Mixins, Prop, Watch} from "vue-property-decorator";
    import {Doughnut, mixins } from "vue-chartjs";
    import {ChartOptions} from "chart.js";

    @Component
    export default class PieChart extends Mixins(Doughnut, mixins.reactiveProp) {

        @Prop({ type: Object, required: false })
        readonly options!: ChartOptions;

        mounted() {
            this.renderChart(this.chartData, this.options);
        }
    }

