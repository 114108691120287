
import {Component, Vue, Watch} from "vue-property-decorator";

import AppBar from '@/components/AppBar.vue';
import AppDrawer from '@/components/AppDrawer.vue';
import {AuthState, onAuthUIStateChange} from '@aws-amplify/ui-components';
import {SecurityMethod} from "@/domain/security-info";

@Component({
  computed: {
    AuthState() {
      return AuthState
    }
  },
  components: {
    AppBar,
    AppDrawer
  },
  data: () => ({
    user: undefined,
    authState: undefined as AuthState | undefined,
    unsubscribeAuth: undefined,
  })
})
export default class App extends Vue {
  get pageTitle() {
    let title: string | null = null;

    if (this.$route.meta.title) {
      title = this.$route.meta.title;
    }

    if (this.$route.matched.length > 0) {
      title = this.$route.matched[0].meta.title;
    }

    return title ? `E-Connector - ${title}` : 'E-Connector';
  }

  get isOpen(): boolean {
    return this.$store.state.isDrawerOpen;
  }

  set isOpen(flag: boolean) {
    this.$store.commit("SET_DRAWER", flag);
  }

  get isCognito(): boolean {
    // @ts-ignore
    return this.$securityInfo.securityMethod == SecurityMethod.COGNITO;
  }

  @Watch('pageTitle')
  onPageTitleChanged(newTitle: string) {
    document.title = newTitle;
  }

  mounted() {
    document.title = this.pageTitle;
  }

  created() {
    this.$data.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.$data.authState = authState;
      this.$data.user = authData;
    });
  }

  beforeDestroy() {
    this.$data.unsubscribeAuth();
  }
}
