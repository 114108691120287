import {Auth} from '@aws-amplify/auth';
import Vue from "vue";

function getAmplifyUser(): null | any {
  return Auth.currentAuthenticatedUser()
    .then((data: any) => {
      if (data && data.signInUserSession) {
        return data;
      }
      return null;
    })
    .catch((e: any): null => {
      return null;
    });
}

function isCognito(): boolean {
  // @ts-ignore
  return this.$securityInfo.securityMethod === 'COGNITO';
}

export { getAmplifyUser, isCognito };
