import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VAppBar,{staticStyle:{"height":"53pt"},style:(_vm.cssVars()),attrs:{"app":"","clipped-left":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();_vm.isDrawerOpenModel = !_vm.isDrawerOpenModel}}},[_c(VIcon,[_vm._v(_vm._s(_vm.menuIcon))])],1),_c(VToolbarTitle,[_vm._v(_vm._s(_vm.pageTitle))]),_c(VImg,{attrs:{"src":require('../assets/logo_bar.png'),"min-height":"100%","max-height":"100%","contain":"","position":"right"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }