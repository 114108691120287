import Vue from 'vue';
import Vuetify, {colors} from 'vuetify/lib';

import '@fortawesome/fontawesome-free/css/all.css';
const custom_colors = require('../styles/colors');

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi'
    },
    options: {
        customProperties: true
    },
    theme: {
        dark: false,
        themes: {
            light: custom_colors.theme_colors,
        }
    }
});
