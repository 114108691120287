import {Module} from "vuex";
import Vue from "vue";
import axios from "axios";
import qs from "qs";
import {Filter, Pageable} from "@/domain/api";

export interface ApiEntity {
    id: number;
}
export interface ApiState<T extends ApiEntity> {
    loading: boolean;

    page: number;
    pageSize: number;
    sort: string;
    descending: boolean;

    totalItems: number;
    items: T[];
    filters: { [name: string]: any };
}

export function createApiModule<T extends ApiEntity>(baseUrl: string, initialState?: Partial<ApiState<T>>): Module<ApiState<T>, any> {
    baseUrl = process.env.VUE_APP_ECONNECTOR_API + baseUrl
    return {
        namespaced: true,
        state: {
            loading: false,

            page: 0,
            pageSize: 10,
            sort: "date",
            descending: true,

            totalItems: 0,
            items: [],
            filters: Object(),
            ...initialState
        },
        mutations: {
            SET_LOADING(state, loading: boolean) {
                state.loading = loading;
            },
            SET_ITEMS(state, items: T[]) {
                state.items = items
            },
            SET_TOTAL_ITEMS(state, totalItems: number) {
                state.totalItems = totalItems;
            },
            UPDATE_ITEM(state, item: T) {
                const existingIndex = state.items.findIndex((entity) => entity.id === item.id);

                if (existingIndex === -1) {
                    state.items.push(item);
                }

                Vue.set(state.items, existingIndex, item);
            },
            DELETE_ITEM(state, id: number) {
                const existingIndex = state.items.findIndex((entity) => entity.id === id);

                state.items.splice(existingIndex, 1);
            },
            SET_PAGE(state, page: number) {
                state.page = page;
            },
            SET_PAGE_SIZE(state, pageSize: number) {
                state.pageSize = pageSize;
            },
            SET_SORT(state, sort: string) {
                state.sort = sort;
            },
            SET_DESCENDING(state, descending: boolean) {
                state.descending = descending;
            },
            SET_FILTER(state, filter: Filter) {
                Vue.set(state.filters, filter.name, filter.value);
            },
            CLEAR_FILTERS(state) {
                state.filters = Object()
            }
        },
        actions: {
            async setOptions({ commit, dispatch }, options: any) {
                commit("SET_SORT", options.sortBy[0]);
                commit("SET_DESCENDING", options.sortDesc[0]);
                //data-table starts at 1, but server starts at 0
                commit("SET_PAGE", options.page - 1);
                commit("SET_PAGE_SIZE", options.itemsPerPage);
            },
            async fetchPage({ commit, state }) {
                commit("SET_LOADING", true);

                const response = await axios.get<Pageable<T>>(baseUrl, {
                    params: {
                        page: state.page,
                        size: state.pageSize,
                        sort: state.sort,
                        descending: state.descending,
                        ...state.filters
                    },
                    paramsSerializer: params => {
                        return qs.stringify(params, { arrayFormat: 'comma' });
                    }
                });

                commit("SET_ITEMS", response.data.content);
                commit("SET_TOTAL_ITEMS", response.data.totalElements)

                commit("SET_LOADING", false);

                return response.data;
            },
            async fetchAll({ commit }) {
                commit("SET_LOADING", true);
                const response = await axios.get<T[]>(baseUrl);

                commit("SET_ITEMS", response.data);

                commit("SET_LOADING", false);

                return response.data;
            },
            async fetchOne({ commit }, id: number) {
                const response = await axios.get<T>(baseUrl + "/" + id);

                commit("UPDATE_ITEM", response.data);

                return response.data;
            },
            async updateOne({ commit }, item: T) {
                const response = await axios.put<T>(baseUrl + "/" + item.id, item);

                commit("UPDATE_ITEM", response.data);

                return response.data;
            },
            async createOne({ commit }, item: T) {
                const response = await axios.post<T>(baseUrl, item);

                commit("UPDATE_ITEM", response.data);

                return response.data;
            },
            async deleteOne({ commit }, id: number) {
                const response = await axios.delete(baseUrl + "/" + id);

                commit("DELETE_ITEM", id);
            }
        },
        getters: {
            dataTableOptions(state) {
                return {
                    sortBy: [state.sort],
                    sortDesc: [state.descending],
                    itemsPerPage: state.pageSize,
                    page: state.page + 1
                }
            }
        }
    }
}
