import {Module} from "vuex";
import axios from "axios";
import {DeviceStatResponse, StatisticDevice} from "@/domain/device";
import {StatisticMessage} from "@/domain";
import ChargePoints from "@/views/ChargePoints.vue";
import CentralSystems from "@/views/CentralSystems.vue";

export interface DashboardState {
    totalMessages: number;
    messages: StatisticMessage[];
    chargePoints: StatisticDevice,
    centralSystems: StatisticDevice,
}

const module: Module<DashboardState, any> = {

    namespaced: true,
    state: {
        totalMessages: 0,
        messages: [],
        chargePoints: {
            connected: 0,
            total: 0
        },
        centralSystems: {
            connected: 0,
            total: 0
        }
    },
    mutations: {
        SET_MESSAGES(state, items: StatisticMessage[]) {
            state.messages = items;
        },
        SET_CHARGEPOINTS(state, items: StatisticDevice) {
            state.chargePoints = items;
        },
        SET_CENTRALSYSTEMS(state, items: StatisticDevice) {
            state.centralSystems = items;
        },
        SET_TOTAL_MESSAGES(state, items: number) {
            state.totalMessages = items;
        }
    },
    actions: {
        async fetchMessageStatistics({ commit }) {
            const response = await axios.get<StatisticMessage[]>(process.env.VUE_APP_ECONNECTOR_API + "api/stats/messages");

            commit("SET_MESSAGES", response.data);

            return response.data;
        },
        async fetchConnectedDevices({ commit }) {
            const response = await axios.get<DeviceStatResponse>(process.env.VUE_APP_ECONNECTOR_API + "api/stats/devices")

            commit("SET_CHARGEPOINTS", response.data.chargePoints);

            commit("SET_CENTRALSYSTEMS", response.data.centralSystems);

            return response.data;
        },
        async fetchAllMessages({ commit }) {
            const response = await axios.get<number>(process.env.VUE_APP_ECONNECTOR_API + "api/stats/totalmessages");

            commit("SET_TOTAL_MESSAGES", response.data);

            return response.data;
        }
    }
};

export default module;
