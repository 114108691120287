import {Module} from "vuex";
import axios from "axios";
import Config from "@/domain/config";

export interface SpecificState {
    config: Config;
}

const module: Module<SpecificState, any> = {

    namespaced: true,
    actions: {
        async linkCentralStation({ commit }, item ) {
            const response = await axios.post(process.env.VUE_APP_ECONNECTOR_API + "api/chargepoints/link" + "/" + item.id, item);

            return response.data;
        },
        async getConfig() {
            const response = await axios.get<Config>(process.env.VUE_APP_ECONNECTOR_API + "api/config");
            return response.data;
        }
    }
};

export default module;
