
    import {Component, Prop, PropSync, Vue} from "vue-property-decorator";

    @Component
    export default class AppBar extends Vue {
        @Prop({ type: String, default: '' })
        readonly pageTitle!: string;

        @PropSync('isDrawerOpen')
        isDrawerOpenModel!: boolean;

        get menuIcon() {
            if (this.isDrawerOpenModel) {
                return 'mdi-menu-open'
            }

            return 'mdi-menu';
        }

        cssVars() {
            return {
                '--bar-background': this.$vuetify.theme.themes.light.bar_background}
        }
    }
