import axios from "axios";
import {getAmplifyUser} from './amplify';

axios.defaults.baseURL = process.env.BASE_URL;

const configureInterceptor = () => {
  axios.interceptors.request.use(
    async function(config) {
      const user = await getAmplifyUser();

      const userSession = user
        .getSignInUserSession();
      const token = userSession ? userSession.getAccessToken().getJwtToken() : null;

      if (token) {
        // @ts-ignore
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    function(error: Error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );
};

export { configureInterceptor };
