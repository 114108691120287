export enum SecurityMethod {
  BASIC_AUTH = "BASIC_AUTH",
  OAUTH2 = "OAUTH2",
  COGNITO = "COGNITO",
}

export interface SecurityInfo {
  securityMethod: SecurityMethod;
  cognitoRegion?: string;
  cognitoUserPoolId?: string;
  cognitoAppClientId?: string;
}
