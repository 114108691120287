import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store'
import vuetify from './plugins/vuetify';
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';
import { Amplify } from 'aws-amplify';
import {configureInterceptor} from "@/boot/axios";
import axios from "axios";
import {SecurityInfo} from "@/domain/security-info";

const securityInfoEndpoint = '/api/meta/security-info';
axios.get(securityInfoEndpoint).then((response) => {
  const securityInfo = response.data as SecurityInfo;
  Vue.prototype.$securityInfo = securityInfo;

  if (securityInfo.securityMethod === 'COGNITO') {
    // Setup for AWS Cognito/Amplify
    const awsConfig = {
      Auth: {
        region: securityInfo.cognitoRegion,
        userPoolId: securityInfo.cognitoUserPoolId,
        userPoolWebClientId: securityInfo.cognitoAppClientId,
      },
    };

    Amplify.configure(awsConfig);

    applyPolyfills().then(() => {
      defineCustomElements(window);
    });

    configureInterceptor();

  }

  // Always ignore these elements.
  Vue.config.ignoredElements = [/amplify-\w*/];

  // Generic setup regardless of security method.
  Vue.config.productionTip = false;

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app');
});
