import Vue from 'vue'
import Vuex from 'vuex'

import BasicAuthDeviationsModule from './basic-auth-deviations';
import CentralSystemsModule from './centralsystems';
import ChargePointsModule from './chargepoints';
import DashboardModule from './dashboard';
import MessagesModule from './messages';
import NetworkProfilesModule from './network-profiles';
import SpecificModule from './specific';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isDrawerOpen: true
    },
    mutations: {
        SET_DRAWER(state: any, flag: boolean) {
            state.isDrawerOpen = flag;
        }
    },
    modules: {
        basicAuthDeviations: BasicAuthDeviationsModule,
        centralSystems: CentralSystemsModule,
        chargePoints: ChargePointsModule,
        dashboard: DashboardModule,
        messages: MessagesModule,
        networkProfiles: NetworkProfilesModule,
        specific: SpecificModule
    }
})
