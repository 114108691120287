import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "@/views/Dashboard.vue";
import {RouteConfig} from "vue-router/types/router";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard'
    }
  },
  {
    path: '/chargepoints',
    name: 'chargepoints',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "chargepoints" */ '../views/ChargePoints.vue'),
    meta: {
      title: 'Charge Points'
    },
    children: [
      {
        path: 'new',
        name: 'chargepoints-dialog-new',
        component: () => import(/* webpackChunkName: "chargepoints-dialog-new" */'../views/NewChargePointDialog.vue')
      },
      {
        path: 'import',
        name: 'chargepoints-dialog-import',
        component: () => import(/* webpackChunkName: "chargepoints-dialog-import" */'../views/ImportChargePointsDialog.vue')
      },
      {
        path: ':id/edit',
        name: 'chargepoints-dialog-edit',
        component: () => import(/* webpackChunkName: "chargepoints-dialog-edit" */'../views/EditChargePointDialog.vue')
      },
      {
        path: ':id/delete',
        name: 'chargepoints-dialog-delete',
        component: () => import(/* webpackChunkName: "chargepoints-dialog-delete" */ '../views/DeleteChargePointDialog.vue')
      }
    ]
  },
  {
    path: '/centralsystems',
    name: 'centralsystems',
    component: () => import(/* webpackChunkName: "centralsystems" */ '../views/CentralSystems.vue'),
    meta: {
      title: 'Central Systems'
    },
    children: [
      {
        path: 'new',
        name: 'centralsystems-dialog-new',
        component: () => import(/* webpackChunkName: "centralsystems-dialog-new" */'../views/NewCentralSystemDialog.vue')
      },
      {
        path: ':id/edit',
        name: 'centralsystems-dialog-edit',
        component: () => import(/* webpackChunkName: "centralsystems-dialog-edit" */'../views/EditCentralSystemDialog.vue')
      },
      {
        path: ':id/delete',
        name: 'centralsystems-dialog-delete',
        component: () => import(/* webpackChunkName: "centralsystems-dialog-delete" */'../views/DeleteCentralSystemDialog.vue')
      }
    ]
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue'),
    meta: {
      title: 'Messages'
    },
    children: [
      {
        path: ':id/info',
        name: 'messages-dialog-info',
        component: () => import(/* webpackChunkName: "messages-dialog-info" */ '../views/InfoMessageDialog.vue')
      }
    ]
  },
  {
    path: '/network-profiles',
    name: 'network-profiles',
    component: () => import(/* webpackChunkName: "network-profiles" */ '../views/NetworkProfiles.vue'),
    meta: {
      title: 'Network Profiles'
    },
    children: [
      {
        path: 'new',
        name: 'network-profile-new',
        component: () => import(/* webpackChunkName: "network-profile-new" */ '../views/NetworkProfileNew.vue')
      },
      {
        path: ':id/edit',
        name: 'network-profile-edit',
        component: () => import(/* webpackChunkName: "network-profile-edit" */ '../views/NetworkProfileEdit.vue')
      }
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router
