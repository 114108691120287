export const theme_colors = {
    primary: '#DE794B',
    secondary: '#FFFFFF',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#f31722',
    success: '#4CAF50',
    warning: '#FFC107',
    back_secondary: '#3D5169',
    drawer_back: '#FFFFFF',
    drawer_primary: '#000000',
    drawer_secondary: '#000000',
    drawer_icon: '#DE794B',
    graph_first: '#78B0DA',
    graph_second: '#3A567F',
    bar_background: '#3A527C'
}
